<script setup lang="ts">
import { InvoiceRowCategoryResource, InvoiceRowResource } from '@/types/invoice-row';
import { copyObject } from '@/util/object-helpers';
import { z } from 'zod';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import ModeSelector from '@/components/Inputs/Components/ModeSelector.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { getItemFromArrayBasedOnId, getKey, numberWithCommas } from '@/util/globals';
import NumberInput from '@/components/Inputs/NumberInput.vue';
import PercentInput from '@/components/Inputs/PercentInput.vue';
import ButtonGroup from '@/components/Inputs/Components/ButtonGroup.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import VButton from '@/components/Inputs/VButton.vue';
import { computed, nextTick, ref } from 'vue';
import SearchBar from '@/components/Documents/fragments/SearchBar.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';

type Props = {
  initalRow: InvoiceRowResource | null;
  model: string;
  modelId: number;
  onlyFromScratch?: boolean | null;
  withVat?: boolean | null;
  products: InvoiceRowResource[];
  categories: InvoiceRowCategoryResource[];
};

const props = withDefaults(defineProps<Props>(), {
  onlyFromScratch: false,
  withVat: true,
  categories: () => [],
  products: () => [],
});

const emit = defineEmits<{
  (event: 'updated', arg: InvoiceRowResource): void;
  (event: 'created', arg: InvoiceRowResource): void;
  (event: 'deleted', arg: number): void;
  (event: 'closed'): void;
}>();

const toast = useToast();
const { assertReadyToDeleteModal } = useDeleteObjectModal();

const working = ref(false);
const workingOnParentId = ref(null);
const activeTab = ref('newItem');
const rowTypes = [
  {
    title: 'Number',
    component: 'invoice-row-number',
  },
  {
    title: 'List',
    component: 'invoice-row-list',
  },
] as const;

if (props.products.length > 0 && getKey(props.initalRow, 'id') === null) {
  activeTab.value = props.onlyFromScratch ? 'newItem' : 'fromPriceList';
}

const rowSchema = z.object({
  component: z.enum(['invoice-row-number', 'invoice-row-list']),
  title: z.string().nullable(),
  category_id: z.number().nullable(),
  quantity: z.string(),
  price: z.string(),
  discount: z.string(),
  vat: z.string(),
  total: z.string(),
  is_cost: z.boolean(),
  category: z
    .object({
      id: z.number(),
      title: z.string(),
    })
    .nullable(),
  options: z.array(z.object({ title: z.string(), value: z.number() })),
});

type RowType = z.infer<typeof rowSchema>;

const row = ref<RowType>({
  component: getKey(props.initalRow, 'component', rowTypes[0].component),
  title: getKey(props.initalRow, 'title', null),
  category_id: getKey(props.initalRow, 'category_id', null),
  quantity: getKey(props.initalRow, 'quantity', 1.0),
  price: getKey(props.initalRow, 'price', 0),
  total: getKey(props.initalRow, 'total', 0),
  discount: props.initalRow?.discount ?? '0',
  vat: props.withVat ? (props.initalRow?.vat ?? '0') : '0',
  category: getKey(props.initalRow, 'category', null),
  is_cost: getKey(props.initalRow, 'is_cost', true),
  options: getKey(props.initalRow, 'options', []),
});

const localProducts = ref(copyObject(props.products));
const selectedProductIds = ref([]);
const displayCategoryId = ref(0);

const newListTitle = ref<string | null>(null);
const newListValue = ref(0);
const searchText = ref('');
const hasCalculatedTotal = ref(true);

const componentHasList = computed(() => {
  return row.value && row.value.component === 'invoice-row-list';
});

const deleteRow = async (close: () => void) => {
  if (!props.initalRow?.id) return;

  const deleteIt = await assertReadyToDeleteModal(
    'Delete Row',
    `Are you sure that you want to delete ${row.value.title} row?`
  );
  if (!deleteIt) return;

  await axios.delete(`/api/invoice-rows/${props.initalRow?.id}`);

  toast.success('Row deleted');

  emit('deleted', props.initalRow?.id);
  close();
};

const addCategoryToRow = () => {
  row.value.category = getItemFromArrayBasedOnId(row.value?.category_id, props.categories, null);

  if (row.value?.category_id) {
    const index = _.findIndex(props.categories, (c) => c.id === row.value?.category_id);
    if (index > -1) {
      row.value.category = {
        id: props.categories[index].id,
        title: props.categories[index].title,
      };
    } else {
      row.value.category = null;
    }
  } else {
    row.value.category = null;
  }
};

const calculateTotal = () => {
  if (!row.value.quantity || !row.value.price) return;
  row.value.total = Number(
    Math.round(
      parseFloat(row.value.quantity) * parseFloat(row.value.price) * (1 - parseFloat(row.value.discount)) * 100
    ) / 100
  ).toFixed(2);

  hasCalculatedTotal.value = false;
  nextTick(() => {
    hasCalculatedTotal.value = true;
  });
};

const updateRow = async (close: () => void) => {
  if (!props.initalRow?.id) {
    toast.warning('Something went wrong');
    return;
  }

  calculateTotal();

  await axios.patch(`/api/invoice-rows/${props.initalRow.id}`, {
    title: row.value?.title,
    component: row.value?.component,
    options: row.value?.options,
    price: row.value?.price,
    total: row.value?.total,
    quantity: row.value?.quantity,
    category_id: row.value?.category_id,
    vat: props.withVat ? row.value?.vat : 0,
    discount: row.value?.discount,
    is_cost: row.value?.is_cost,
  });

  toast.success(`${row.value?.title} updated`);
  addCategoryToRow();
  emit('updated', { ...props.initalRow, ...row.value });
  close();
};

const doTheActualAddingOfRow = async (row) => {
  workingOnParentId.value = row.id;
  const { data } = await axios.post('/api/invoice-rows', {
    parent_id: row.id,
    title: row.title,
    component: row.component,
    options: row.options,
    price: row.price,
    total: row.total,
    quantity: row.quantity,
    category_id: row.category_id,
    vat: props.withVat ? row.vat : 0,
    discount: row.discount,
    is_cost: row.is_cost,
    model_type: `App\\${props.model}`,
    model_id: props.modelId,
  });
  workingOnParentId.value = null;
  emit('created', data);
};

const addRows = async (close: () => void) => {
  working.value = true;
  if (selectedProductIds.value.length) {
    for (let i = 0; i < selectedProductIds.value.length; i++) {
      const index = _.findIndex(localProducts.value, (r) => r.id === selectedProductIds.value[i]);
      if (index > -1) {
        await doTheActualAddingOfRow(localProducts.value[index]);
      }
    }
    selectedProductIds.value = [];
    close();
  } else {
    if (!componentHasList.value) {
      row.value.options = null;
    }
    row.value.total = Number(
      parseFloat(row.value?.price) * parseFloat(row.value?.quantity) * (1 - parseFloat(row.value?.discount))
    ).toFixed(2);
    await doTheActualAddingOfRow(row.value);
    close();
  }
  working.value = false;
};

const addListOption = () => {
  if (!newListValue.value || !newListTitle.value) return;

  if (!row.value?.options) {
    row.value.options = [
      {
        title: newListTitle.value,
        value: newListValue.value,
      },
    ];
  } else {
    row.value.options.push({
      title: newListTitle.value,
      value: newListValue.value,
    });
  }

  newListTitle.value = '';
  newListValue.value = 0;
  row.value.options = row.value.options.sort((a, b) => a.value - b.value);
};

const totalInkVatUpdated = (value: string) => {
  const newPrice =
    parseFloat(value) /
    (parseFloat(row.value.quantity) * (1 + parseFloat(row.value.vat)) * (1 - parseFloat(row.value.discount)));

  if (!Number.isInteger(newPrice)) {
    toast.success(`Price has been rounded of by ${newPrice - Math.round(newPrice)}.`);
  }

  row.value.price = Number(Math.round(newPrice * 100) / 100).toFixed(2);

  row.value.total = Number(
    Math.round(
      parseFloat(row.value.price) * parseFloat(row.value.quantity) * (1 - parseFloat(row.value.discount)) * 100
    ) / 100
  ).toFixed(2);

  hasCalculatedTotal.value = false;
  nextTick(() => {
    hasCalculatedTotal.value = true;
  });
};

const createButtonText = computed(() => {
  if (props.model === 'InvoiceBasis' && !props.initalRow?.id && props.products.length > 0) {
    return activeTab.value === 'newItem' ? 'Add Product' : `Add ${selectedProductIds.value.length} Product(s)`;
  }
  return 'Add Product';
});

const allCategories = computed(() => {
  const array = props.categories.map((c) => {
    return {
      id: c.id,
      title: c.title,
    };
  });
  array.unshift({
    id: 0,
    title: 'All',
  });
  return array;
});

const totalEksVat = computed(() => {
  const total = parseFloat(row.value?.price) * parseFloat(row.value?.quantity);
  if (!row.value.discount) return numberWithCommas(total);
  return numberWithCommas(total * (1 - parseFloat(row.value?.discount)));
});

const vat = computed(() => {
  const total = parseFloat(row.value?.price) * parseFloat(row.value?.quantity);

  if (!row.value.vat) return numberWithCommas(0);
  if (!row.value.discount) return numberWithCommas(total * parseFloat(row.value?.vat));
  return numberWithCommas(total * (1 - parseFloat(row.value?.discount)) * parseFloat(row.value?.vat));
});

const totalIncVat = computed(() => {
  const total = parseFloat(row.value?.price) * parseFloat(row.value?.quantity);
  if (!row.value.vat) return numberWithCommas(total);
  if (!row.value.discount) return numberWithCommas(total * parseFloat(1 + row.value?.vat));
  return numberWithCommas(total * (1 - parseFloat(row.value?.discount)) * (1 + parseFloat(row.value?.vat)));
});

const sortedLocalProducts = computed(() => {
  const existingCategoryIds = props.categories.map((category) => category.id);
  return props.categories
    .map((category: InvoiceRowCategoryResource) => {
      return {
        id: category.id,
        title: category.title,
        rows: localProducts.value.filter((p) => p.category_id === category.id),
      };
    })
    .concat([
      {
        id: null,
        title: 'Without Category',
        rows: localProducts.value.filter((p) => p.category_id === null || !existingCategoryIds.includes(p.category_id)),
      },
    ])
    .flatMap((category) => {
      return category.rows;
    })
    .filter((r) => (displayCategoryId.value === 0 ? true : r.category_id === displayCategoryId.value))
    .filter((r) =>
      searchText.value === null || searchText.value.length === 0
        ? true
        : r.title.toLowerCase().includes(searchText.value.toLowerCase()) ||
          getItemFromArrayBasedOnId(r.category_id, props.categories, {
            title: '',
          })
            .title.toLowerCase()
            .includes(searchText.value.toLowerCase())
    );
});

const updateOption = (option, newValue) => {
  if (parseFloat(row.value.price) === parseFloat(option.value)) {
    row.value.price = newValue;
  }
  option.value = newValue;
};
</script>

<template>
  <CrudModal
    :update="!isNaN(initalRow?.id)"
    :title="!isNaN(initalRow?.id) ? 'Edit Product' : 'Create Product'"
    :create-button-text="createButtonText"
    :disabled="
      activeTab === 'newItem' ? !row.title : activeTab === 'fromPriceList' ? selectedProductIds.length === 0 : false
    "
    title-highlight="Product"
    large
    :loading="working"
    @delete="deleteRow"
    @create="addRows"
    @update="updateRow"
    @closed="$emit('closed')">
    <div class="form-layout grid-cols-2">
      <ModeSelector
        v-if="model === 'InvoiceBasis' && !initalRow?.id && localProducts.length > 0 && !props.onlyFromScratch"
        v-model="activeTab"
        class="col-span-2"
        :modes="[
          { value: 'newItem', name: 'Blank' },
          { value: 'fromPriceList', name: 'From Price List' },
        ]" />

      <VSelect
        v-if="activeTab === 'newItem'"
        v-model="row.component"
        :can-edit="!initalRow?.id"
        wrapper-class="col-span-2"
        label="Type"
        :options="rowTypes"
        option-value="title"
        option-key="component"
        @change="selectedProductIds = []" />

      <div
        v-if="activeTab === 'fromPriceList' && localProducts.length"
        class="col-span-2">
        <div class="gap-edge grid grid-cols-2">
          <VSelect
            v-model="displayCategoryId"
            :nullable="true"
            nullable-display-text="Without Category"
            :options="allCategories"
            option-value="title"
            :can-edit="!working"
            label="Category" />

          <div>
            <InputLabel label="Search" />
            <SearchBar
              v-model="searchText"
              :can-edit="!working"
              placeholder-string="Search" />
          </div>
        </div>
        <div class="overflow-auto h-[40vh] mt-5">
          <VTable
            edge-to-edge
            sticky-header
            row-size="medium">
            <template #head>
              <VTableRow head>
                <VTableCell style="width: 50px" />
                <VTableCell v-if="categories.length > 0 && displayCategoryId === 0"> Category</VTableCell>
                <VTableCell> Description</VTableCell>
                <VTableCell classes="text-right"> Unit Price</VTableCell>
                <VTableCell
                  style="width: 130px"
                  classes="text-right">
                  Quantity
                </VTableCell>
                <VTableCell classes="text-right"> Total</VTableCell>
              </VTableRow>
            </template>

            <VTableRow
              v-for="product in sortedLocalProducts"
              :key="product.id"
              clickable
              :main-row="selectedProductIds.includes(product.id)"
              @click="
                working
                  ? null
                  : selectedProductIds.includes(product.id)
                    ? selectedProductIds.splice(selectedProductIds.indexOf(product.id), 1)
                    : selectedProductIds.push(product.id)
              ">
              <VTableCell>
                <CheckBox
                  :loading="working && workingOnParentId === product.id"
                  :can-edit="!working"
                  :model-value="selectedProductIds.includes(product.id)"></CheckBox>
              </VTableCell>

              <VTableCell v-if="categories.length > 0 && displayCategoryId === 0">
                {{
                  getItemFromArrayBasedOnId(product.category_id, categories, {
                    title: 'N/A',
                  }).title
                }}
              </VTableCell>
              <VTableCell>
                {{ product.title }}
              </VTableCell>
              <VTableCell classes="text-right">
                {{ product.price }}
              </VTableCell>
              <VTableCell
                v-if="selectedProductIds.includes(product.id)"
                class="!py-0 !px-4"
                @click.stop>
                <NumberInput
                  v-model="product.quantity"
                  size="block"
                  :can-edit="!working"
                  class="[&>div]:h-[28px]"
                  :min="-99999999999"
                  :with-decimals="false"
                  :with-controlles="true"
                  @update:model-value="calculateTotal()" />
              </VTableCell>
              <VTableCell
                v-else
                classes="text-right">
              </VTableCell>
              <VTableCell classes="text-right">
                <span v-if="selectedProductIds.includes(product.id)">
                  {{ product.quantity * product.price * (1 - product.discount) }}
                </span>
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>
      </div>
      <template v-else>
        <TextInput
          v-model="row.title"
          label="Description" />

        <VSelect
          v-if="categories.length > 0"
          v-model="row.category_id"
          nullable
          nullable-display-text="Without Category"
          :options="categories"
          option-value="title"
          label="Category" />

        <NumberInput
          v-model="row.quantity"
          label="Quantity"
          size="block"
          :emit-null-value="false"
          :min="-99999999999"
          :with-decimals="true"
          placeholder="Quantity"
          @update:model-value="calculateTotal()" />

        <NumberInput
          v-if="!componentHasList"
          v-model="row.price"
          label="Unit Price"
          size="block"
          :min="-99999999999"
          :emit-null-value="false"
          :with-decimals="true"
          placeholder="Unit Price"
          @update:model-value="calculateTotal()" />

        <VSelect
          v-if="componentHasList"
          v-model="row.price"
          label="Unit Price"
          :can-edit="row.options.length > 0"
          :options="
            row.options.map((o) => ({
              name: o.title + ' (' + o.value + ')',
              id: o.value,
            }))
          "
          placeholder="Unit Price"
          @update:model-value="calculateTotal()" />

        <PercentInput
          v-model="row.discount"
          label="Discount"
          size="block"
          placeholder="Discount"
          @update:model-value="calculateTotal()" />

        <PercentInput
          v-if="withVat"
          v-model="row.vat"
          label="VAT"
          size="block"
          placeholder="VAT"
          @update:model-value="calculateTotal()" />

        <div
          v-if="componentHasList"
          class="col-span-2">
          <h4>Unit Price Options</h4>
          <VTable row-size="small">
            <VTableRow v-for="(option, index) in row.options">
              <VTableCell>
                <TextInput v-model="option.title" />
              </VTableCell>
              <VTableCell>
                <NumberInput
                  :model-value="option.value"
                  with-decimals
                  :dont-force-decimals="false"
                  :max-decimals="2"
                  @blur="updateOption(option, $event)" />
              </VTableCell>
              <VTableCell>
                <VButton
                  type="inTable"
                  icon="fa-trash"
                  @click="row.options.splice(index, 1)" />
              </VTableCell>
            </VTableRow>
            <template #footer>
              <tfoot>
                <VTableRow main-row>
                  <VTableCell>
                    <TextInput
                      v-model="newListTitle"
                      placeholder="Type a title and press enter" />
                  </VTableCell>
                  <VTableCell>
                    <NumberInput
                      v-model="newListValue"
                      with-decimals
                      :dont-force-decimals="false"
                      :max-decimals="2"
                      placeholder="Type the value and press enter"
                      @keydown.enter="addListOption" />
                  </VTableCell>
                  <VTableCell>
                    <ButtonGroup class="h-[40px]">
                      <VButton
                        size="extra-small"
                        type="success"
                        icon="fa-save"
                        @click="addListOption" />
                      <VButton
                        size="extra-small"
                        type="warning"
                        icon="fa-times"
                        @click="[(newListTitle = ''), (newListValue = 0)]" />
                    </ButtonGroup>
                  </VTableCell>
                </VTableRow>
              </tfoot>
            </template>
          </VTable>
        </div>

        <div class="col-span-2 grid sm:grid-cols-3">
          <div>
            <InputLabel :label="'Total ' + (withVat ? 'Eks vat' : '')" />
            <strong>{{ totalEksVat }}</strong>
          </div>
          <div v-if="withVat">
            <InputLabel label="VAT" />
            <strong>{{ vat }}</strong>
          </div>
          <div>
            <InputLabel :label="'Total' + (withVat ? ' inc VAT' : '')" />

            <NumberInput
              v-if="!componentHasList && hasCalculatedTotal"
              :model-value="numberWithCommas(row.total * (1 + parseFloat(row.vat)))"
              size="block"
              :can-edit="parseFloat(row.discount) < 1"
              :with-decimals="true"
              :emit-null-value="false"
              placeholder="TOTAL INK VAT"
              @blur="totalInkVatUpdated" />

            <strong v-else>
              {{ totalIncVat }}
            </strong>
          </div>
        </div>
      </template>
    </div>
  </CrudModal>
</template>
