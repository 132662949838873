<script lang="ts" setup>
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { ref } from 'vue';
import { getKey } from '@/util/globals';
import VButton from '@/components/Inputs/VButton.vue';
import { Action } from '@/components/Inputs/Components/ActionButtons.vue';
import ButtonGroup from '@/components/Inputs/Components/ButtonGroup.vue';
import TextInput from '@/components/Inputs/TextInput.vue';

type Props = {
  actions?: Action[];
};

const props = withDefaults(defineProps<Props>(), {
  actions: () => [],
});

const dropdown = ref(null);
const componentKey = ref(0);
[50, 100, 500, 1000].forEach((time) => {
  setTimeout(() => {
    componentKey.value++;
  }, time);
});

const searchTerm = ref('');
</script>

<template>
  <div class="h-full flex items-center action-button-group-container">
    <div class="flex gap-3">
      <template
        v-for="action in actions.filter((a) => a !== null)"
        :key="action.title">
        <template v-if="action.hasOwnProperty('dropdown')">
          <VDropdown
            :close-on-click="action.hasOwnProperty('closeOnClick') ? action.closeOnClick : true"
            :max-height-dropdown="getKey(action, 'maxHeightDropdown', '300px')"
            :highlight-text="searchTerm"
            :items="
              action.dropdown.filter((d) => {
                return searchTerm.length === 0 ? true : d.title.toLowerCase().includes(searchTerm.toLowerCase());
              })
            ">
            <template
              v-if="getKey(action, 'filterable', false)"
              #aboveDropdown>
              <TextInput
                v-model="searchTerm"
                placeholder="Search"
                set-focus
                square />
            </template>

            <template #click-area>
              <div ref="dropdown">
                <button
                  class="btn-tiny btn-info group relative focus:outline-none"
                  :title="getKey(action, 'hoverText', null)"
                  :disabled="getKey(action, 'disabled', false)">
                  <i
                    v-if="getKey(action, 'withIcon', true)"
                    class="fa fa-fw"
                    :class="
                      action.hasOwnProperty('loading') && action.loading
                        ? 'fa-circle-o-notch fa-spin'
                        : getKey(action, 'icon', 'fa-chevron-down')
                    " />
                  {{ getKey(action, 'title', '') }}

                  <i
                    v-if="getKey(action, 'postIcon', null)"
                    class="fa fa-fw"
                    :class="
                      action.hasOwnProperty('loading') && action.loading
                        ? 'fa-circle-o-notch fa-spin'
                        : getKey(action, 'postIcon')
                    " />
                </button>
              </div>
            </template>
          </VDropdown>
        </template>
        <div v-else-if="getKey(action, 'buttonDropdown', []).length > 0">
          <ButtonGroup>
            <VButton
              size="tiny"
              :type="action.type ?? 'info'"
              :icon="action.icon"
              :title="action.title"
              @click="
                (event) => {
                  if (action.hasOwnProperty('action')) {
                    action.action(event);
                  } else if (action.hasOwnProperty('onClick')) {
                    action.onClick(event);
                  }
                }
              " />
            <VDropdown
              :max-height-dropdown="getKey(action, 'maxHeightDropdown', '300px')"
              :items="action.buttonDropdown">
              <template
                v-if="getKey(action, 'filterable', false)"
                #aboveDropdown>
                HEr
              </template>
              <template #click-area>
                <div ref="dropdown">
                  <button
                    :title="getKey(action, 'buttonDropdownTitle')"
                    class="btn-tiny btn-info !rounded-l-none">
                    <i class="fa fa-fw fa-chevron-down" />
                  </button>
                </div>
              </template>
            </VDropdown>
          </ButtonGroup>
        </div>
        <VButton
          v-else
          size="tiny"
          :type="getKey(action, 'icon', null) === 'fa-trash' ? 'warning' : (action.type ?? 'info')"
          :class="
            (getKey(action, 'icon', null) === 'fa-trash' ? 'btn-outline bg-backgroundColor-content' : '') +
            ' ' +
            getKey(action, 'buttonClasses', '')
          "
          :loading="getKey(action, 'loading', false)"
          :disabled="getKey(action, 'disabled', false)"
          :icon="action.icon"
          :title="action.title"
          :tool-tip-text="getKey(action, 'toolTopText', getKey(action, 'hoverText'))"
          @click="
            (event) => {
              if (action.hasOwnProperty('action')) {
                action.action(event);
              } else if (action.hasOwnProperty('onClick')) {
                action.onClick(event);
              }
            }
          " />
      </template>
    </div>
  </div>
</template>
