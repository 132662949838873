<script setup lang="ts">
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { arrayToJoinString, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import {
  changeAndFormatStamp,
  formatStampAsHumanReadableDate,
  formatStampAsTime,
  getNow,
  isWithinIntervalOfObject,
} from '@/util/timeFunctions';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import VButton from '@/components/Inputs/VButton.vue';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import { getAssignHoursForUserId } from '@/util/group-helpers';
import moment from 'moment/moment';
import { dateFormat, dateTimeFormat } from '@/variables/date-format';
import { getShift } from '@/services/api-shifts';
import { useShiftTypes } from '@/composables/use-shift-types';
import { useTextBoxModal } from '@/composables/modals/use-text-box-modal-modal';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import { useGroup } from '@/composables/use-group';

type Props = {
  shift: {
    isAssigned: boolean;
    userId: number | null;
    user_id: number | null;
    shift_id: number;
    start: string;
    end: string;
    shift_type_id: string;
    events: any[];
    shift_interest_pivots: any[];
  };
  groupId: number;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'replacementDone', ...args: any[]): void;
  (event: 'shiftInterestPivotDeclined', ...args: number): void;
}>();

const shiftResource = ref(null);
const { shiftTypes, fetch: fetchShiftTypes } = useShiftTypes('Group', props.groupId, false);
const { group, fetch: fetchGroup } = useGroup(props.groupId);
const otherUsersInGroup = computed(() => {
  if (!shiftResource.value) return [];
  if (!group) return [];
  const groups = [
    {
      id: group.value.id,
      name: group.value.name,
      members: group.value?.members,
    },
  ].concat(
    group.value.children.map(function (g) {
      return { id: g.id, name: g.name, members: g.members };
    })
  );
  return getItemFromArrayBasedOnId(shiftResource.value.via_id, groups, { members: [] })?.members;
});

const toast = useToast();
const { assertCertain } = useCertaintyModal();

const modalOpen = ref(true);
const working = ref(false);
const assignToUserId = ref<number | null>(null);

const canReplace = computed(() => assignToUserId.value !== null);

const hasLoaded = ref(false);
const eventsTheSameWeek = ref([]);
const fetchShift = async () => {
  const { data } = await getShift(props.shift.shift_id);
  shiftResource.value = data;
};
const fetchEvents = async () => {
  const params = {
    start: changeAndFormatStamp({
      stamp: shiftResource.value.start,
      startOf: 'week',
      addDays: 1,
      format: dateFormat,
    }),
    end: changeAndFormatStamp({
      stamp: shiftResource.value.start,
      startOf: 'week',
      addDays: 8,
      format: dateFormat,
    }),
    with_resource_ids: true,
    with_simple: false,
    with_cancelled: false,
    with_shifts: true,
    with_festivals: false,
    with_colors: false,
  };

  const { data: events } = await axios.get(`/api/groups/${props.groupId}/assignments`, {
    params,
  });

  eventsTheSameWeek.value = events;
};

const otherShiftsOfUserSameTime = computed(() => {
  return eventsTheSameWeek.value
    .filter((e) => getKey(e, 'shift_id') !== null)
    .filter((e) => getKey(e, 'user_id') !== null)
    .filter((s) =>
      isWithinIntervalOfObject(
        shiftResource.value.start,
        shiftResource.value.end,
        moment(s.start).format(dateTimeFormat),
        moment(s.end).format(dateTimeFormat)
      )
    );
});
const eventsOfUsersSameTime = computed(() => {
  return eventsTheSameWeek.value
    .filter((e) => getKey(e, 'event_id', null) !== null)
    .filter((s) =>
      isWithinIntervalOfObject(
        shiftResource.value.start,
        shiftResource.value.end,
        moment(s.start).format(dateTimeFormat),
        moment(s.end).format(dateTimeFormat)
      )
    );
});

const hasShiftInSlot = (user) => {
  return _.findIndex(otherShiftsOfUserSameTime.value, (s) => s.user_id === user.id) > -1;
};

const hasEventInSlot = (user) => {
  return _.findIndex(eventsOfUsersSameTime.value, (s) => s.user_id === user.id) > -1;
};

const setItUp = async () => {
  hasLoaded.value = false;
  await fetchShift();
  await fetchEvents();
  await fetchShiftTypes();
  await fetchGroup();
  // if (groupResource) {
  //   await groupResource.fetch();
  // }
  hasLoaded.value = true;
};
setItUp();

const reassignToUser = async (user: any) => {
  if (!shiftResource.value.user_id) {
    await assignToUser(user);
    return;
  }
  if (user.id === shiftResource.value.user_id) {
    toast.warning('Cannot replace with same person');
    return;
  }
  if (hasShiftInSlot(user) || hasEventInSlot(user)) {
    const certain = await assertCertain(
      'Replace shift',
      `Are you certain you want to replace the shift with ${user.name}?`
    );
    if (!certain) return;
  }

  working.value = true;

  await axios
    .post(`/api/shifts/${shiftResource.value.id}/replace`, {
      user_id: user.id,
    })
    .catch((error) => {
      toast.warning('Something went wrong, please try again later.');
      console.error(error.response.data);
    });
  useToast().success('Shift replaced');
  working.value = false;
  emit('replacementDone', 'Replaced.', shiftResource.value.id, user.id);
  modalOpen.value = false;
  emit('closed');
};

const assignToRequester = async (user) => {
  if (!user.pivot || !user.pivot.id) return;
  working.value = true;
  await axios
    .post(`/api/shift-interest-pivots/${user.pivot.id}/accept`, {
      response_notes: null,
    })
    .catch((error) => {
      toast.warning('Something went wrong, please try again later.');
      console.error(error.response.data);
    });

  useToast().success('Shift Assigned');
  working.value = false;
  emit('replacementDone', 'Assigned.', shiftResource.value.id, user.user_id);
  modalOpen.value = false;
  emit('closed');
};
const declineRequest = async (user) => {
  if (!user.pivot || !user.pivot.id) return;
  const res = await useTextBoxModal(
    'Decline Shift Interest',
    'Are you sure you want to decline? You can give a reason if you want.',
    'Decline Reason',
    'Decline'
  ).showTextBox();
  if (getKey(res, 'response') !== 'confirm') return;
  working.value = true;
  await axios
    .post(`/api/shift-interest-pivots/${user.pivot.id}/decline`, {
      response_notes: getKey(res, 'text'),
    })
    .catch((error) => {
      toast.warning('Something went wrong, please try again later.');
      console.error(error.response.data);
    });
  user.pivot.accepted_at = null;
  user.pivot.declined_at = getNow();
  user.pivot.response_notes = getKey(res, 'text');
  useToast().success('Declined');
  working.value = false;
  emit('shiftInterestPivotDeclined', user.pivot.id);
};

const assignToUser = async (user: any) => {
  if (working.value) return;
  if (shiftResource.value.user_id) {
    await reassignToUser(user);
    return;
  }

  if (hasShiftInSlot(user) || hasEventInSlot(user)) {
    const certain = await assertCertain(
      'Replace shift',
      `Are you certain you want to replace the shift with ${user.name}?`
    );
    if (!certain) return;
  }

  working.value = true;
  await axios
    .patch(`/api/shifts/${shiftResource.value.id}/assign`, {
      user_id: user.id,
    })
    .catch((error) => {
      toast.warning('Something went wrong, please try again later.');
      console.error(error.response.data);
    });

  useToast().success('Shift Assigned');
  working.value = false;
  emit('replacementDone', 'Assigned.', shiftResource.value.id, user.id);
  modalOpen.value = false;
  emit('closed');
};

const getTitleForUser = (user) => {
  const shifts = otherShiftsOfUserSameTime.value.filter((s) => s.user_id === user.id);
  const events = _.uniqBy(
    eventsOfUsersSameTime.value.filter((s) => s.user_id === user.id),
    'id'
  );
  if (shifts.length === 0 && events.length === 0) {
    return `${user.name} is available`;
  }
  let string = `${user.name} `;

  if (shifts.length > 0) {
    string = `${string} has ${shifts.length} shifts in period (${arrayToJoinString(
      shifts.map((s) => `${s.shift_title}: ${formatStampAsTime(s.start)} - ${formatStampAsTime(s.end)}`)
    )})`;
  }
  if (events.length > 0) {
    string = `${string} has ${events.length} events in period (${arrayToJoinString(
      events.map((s) => `${s.title}: ${formatStampAsTime(s.start)} - ${formatStampAsTime(s.end)}`)
    )})`;
  }

  return string;
};

const allInterestedUsers = () => {
  return shiftResource.value.shift_interest_pivots
    .map(function (p) {
      const user = getItemFromArrayBasedOnId(p.user_id, otherUsersInGroup.value, null, 'id');
      if (!user) return null;
      return { ...user, pivot: p };
    })
    .filter((i) => i !== null);
};
const getNameOfVia = () => {
  if (!group.value) return '';
  const groups = [
    {
      id: group.value.id,
      name: group.value.name,
    },
  ].concat(
    group.value.children.map(function (g) {
      return { id: g.id, name: g.name };
    })
  );
  return getItemFromArrayBasedOnId(shiftResource.value.via_id, groups, { name: 'N/A' }).name;
};

const getAssignedWorkLoadTitleForUser = (user) => {
  return (
    user.name +
    ' has been assigned ' +
    getAssignHoursForUserId(user.id, eventsTheSameWeek.value) +
    (user.permissions.hours_week ? ' of ' + user.permissions.hours_week : '') +
    ' during week.'
  );
};
</script>

<template>
  <CrudSlideout
    v-if="modalOpen"
    :loading="working"
    medium
    :base-z-index="110"
    :disabled="working || !canReplace"
    :title="(shiftResource?.user_id !== null ? 'Find Replacement for' : 'Assign') + ' Shift'"
    :only-close-button="true"
    @closed="$emit('closed')">
    <div v-if="!shiftResource || !hasLoaded">
      <div class="text-5xl text-center text-textColor-soft mt-10">
        <i class="fa fa-fw fa-circle-o-notch fa-spin" /> <br />
        <span class=""> Loading </span>
      </div>
    </div>
    <div
      v-else
      class="grid md:grid-cols-[175px_auto] gap-5 pb-4 h-full">
      <div class="pt-[6px] px-edge">
        <div class="flex flex-col gap-5">
          <div>
            <InputLabel
              super-text
              label="Date" />
            {{ formatStampAsHumanReadableDate(shiftResource.start) }}
          </div>
          <div>
            <InputLabel
              super-text
              label="time" />
            {{ formatStampAsTime(shiftResource.start) }} - {{ formatStampAsTime(shiftResource.end) }}
          </div>
          <div>
            <InputLabel
              super-text
              label="Group" />
            {{ getNameOfVia() }}
          </div>
          <div v-if="shiftTypes.length > 0">
            <InputLabel
              super-text
              label="Type" />
            {{ getItemFromArrayBasedOnId(shiftResource.shift_type_id, shiftTypes, { title: 'N/A' }).title }}
          </div>
          <div>
            <InputLabel
              super-text
              label="Events" />
            {{
              shiftResource.events.length === 0
                ? 'N/A'
                : arrayToJoinString(
                    shiftResource.events.map(function (e) {
                      return e.name;
                    })
                  )
            }}
          </div>

          <div>
            <InputLabel
              super-text
              label="Currently Assigned" />
            {{ shiftResource && shiftResource.user ? shiftResource.user.name : 'N/A' }}
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-5 overflow-auto pt-edge h-full">
        <div v-if="shiftResource.for_sale || shiftResource.shift_interest_pivots.length > 0">
          <h3>Requested Users</h3>
          <div
            v-if="allInterestedUsers().length === 0"
            class="italic text-textColor-soft">
            No Interest shown yet
          </div>
          <div class="flex flex-col gap-edge">
            <div
              v-for="user in allInterestedUsers()"
              :class="{ 'group/item': shiftResource.user_id !== user.id }"
              class="grid grid-cols-[25px_35px_auto_110px] items-center border rounded px-2 py-3 min-h-[50px]">
              <div class="pl-2">
                <i
                  :class="
                    'fa fa-fw fa-circle text-xs ' +
                    (hasShiftInSlot(user) || hasEventInSlot(user) ? 'text-warning' : 'text-highlight')
                  " />
              </div>
              <div>
                <img
                  :src="user.avatar_url"
                  class="rounded size-6 object-cover mr-3"
                  alt="user profile picture" />
              </div>
              <div class="flex flex-col">
                <div class="grid grid-cols-[auto_100px] items-center">
                  <div class="flex items-center">
                    <div class="w-[200px] truncate">
                      {{ user.name }}
                    </div>
                  </div>
                  <div
                    class="text-right pr-edge text-sm"
                    :title="getAssignedWorkLoadTitleForUser(user)">
                    {{ getAssignHoursForUserId(user.id, eventsTheSameWeek) }}
                    {{ user.permissions.hours_week ? ' of ' + user.permissions.hours_week : '' }}
                  </div>
                </div>
                <div
                  v-if="user.pivot.claim_notes"
                  class="text-xs text-textColor-soft">
                  {{ user.pivot.claim_notes }}
                </div>
              </div>
              <div
                v-if="user.pivot"
                class="justify-end flex">
                <div
                  v-if="!user.pivot.accepted_at && !user.pivot.declined_at"
                  class="flex gap-2 items-center">
                  <div class="flex flex-col gap-3">
                    <VButton
                      v-if="shiftResource.user_id !== user.id"
                      size="extra-small"
                      icon="fa-check"
                      class="btn-outline-text"
                      type="success"
                      title="Accept"
                      @click="assignToRequester(user)" />
                    <VButton
                      v-if="shiftResource.user_id !== user.id"
                      size="extra-small"
                      icon="fa-times"
                      class="btn-outline-text"
                      type="warning"
                      title="Decline"
                      @click="declineRequest(user)" />

                    <DisplayBadge
                      v-if="shiftResource.user_id === user.id"
                      class="w-[75px]"
                      color="success"
                      text="Assigned" />
                  </div>
                </div>
                <div v-if="user.pivot.accepted_at">
                  <DisplayBadge
                    class="w-[75px]"
                    color="success"
                    text="Accepted" />
                </div>
                <div v-if="user.pivot.declined_at">
                  <DisplayBadge
                    class="w-[75px] group-hover/item:hidden"
                    color="warning"
                    text="Declined" />

                  <VButton
                    v-if="shiftResource.user_id !== user.id"
                    size="extra-small"
                    icon="fa-check"
                    class="btn-outline-text hidden group-hover/item:block"
                    type="success"
                    :title="shiftResource.user_id === null ? 'Assign' : 'Replace'"
                    @click="assignToRequester(user)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3>Available Users</h3>
          <VTable
            edge-to-edge
            sticky-header
            row-size="small">
            <VTableRow
              v-for="user in otherUsersInGroup.filter(
                (user) => !shiftResource.shift_interest_pivots.map((pivot) => pivot.user_id).includes(user.id)
              )"
              :key="user.id"
              :title="getTitleForUser(user)">
              <VTableCell class="!pl-3 !pr-0">
                <i
                  :class="
                    'fa fa-fw fa-circle text-xs ' +
                    (hasShiftInSlot(user) || hasEventInSlot(user) ? 'text-warning' : 'text-highlight')
                  " />
              </VTableCell>
              <VTableCell
                classes=""
                :class="hasShiftInSlot(user) || hasEventInSlot(user) ? 'opacity-50' : ''"
                :style="user.id === shiftResource.user_id ? 'text-decoration: line-through; ' : ''">
                <div class="flex items-center">
                  <img
                    :src="user.avatar_url"
                    class="rounded size-6 object-cover mr-3"
                    alt="user profile picture" />
                  <div class="w-[200px] truncate">
                    {{ user.name }}
                  </div>
                </div>
              </VTableCell>
              <VTableCell
                :class="hasShiftInSlot(user) || hasEventInSlot(user) ? 'opacity-50' : ''"
                style="width: 120px"
                :style="user.id === shiftResource.user_id ? 'text-decoration: line-through; ' : ''">
                <div
                  class="text-right pr-edge text-sm"
                  :title="getAssignedWorkLoadTitleForUser(user)">
                  {{ getAssignHoursForUserId(user.id, eventsTheSameWeek) }}
                  <span v-if="user.permissions.hours_week">of {{ user.permissions.hours_week }}</span>
                </div>
              </VTableCell>
              <template v-if="shiftResource.user_id">
                <VTableCell
                  v-if="user.id !== shiftResource.user_id"
                  style="width: 100px">
                  <VButton
                    size="inTable"
                    title="Replace"
                    @click="reassignToUser(user)"></VButton>
                </VTableCell>
                <VTableCell
                  v-else
                  style="width: 100px"
                  main-cell>
                  <small class="uppercase"><i>Assigned</i></small>
                </VTableCell>
              </template>
              <template v-else>
                <VTableCell style="width: 100px">
                  <VButton
                    size="inTable"
                    title="Assign"
                    @click="assignToUser(user)" />
                </VTableCell>
              </template>
            </VTableRow>
          </VTable>
        </div>
      </div>
    </div>
  </CrudSlideout>
</template>
