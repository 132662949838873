/* eslint-disable no-use-before-define */
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { GroupRegularResource } from '@/types/group';

export const useGroupsStore = defineStore('groups', () => {
  const list = ref<Map<number, GroupRegularResource[] | null>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (groupId: number) => {
    // Echo.join(`On.${modelType}.${modelId}`)
    //   .listen('.eventType.created', async () => {
    //     await fetchEventTypes(modelType, modelId);
    //   })
    //   .listen('.eventType.updated', async () => {
    //     await fetchEventTypes(modelType, modelId);
    //   })
    //   .listen('.eventType.deleted', async () => {
    //     await fetchEventTypes(modelType, modelId);
    //   });
  };

  const fetchGroup = async (groupId: number) => {
    loading.value = true;
    const { data } = await axios.get('/api/groups/' + groupId);
    if (!list.value.has(groupId)) {
      listenForBroadcast(groupId);
    }
    list.value.set(groupId, data);
    loading.value = false;
  };

  return {
    list,
    loading,
    fetchGroup,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGroupsStore, import.meta.hot));
}
