<script lang="ts" setup>
import { DocumentResource } from '@/types/document';
import { inject, nextTick, onBeforeUnmount, ref } from 'vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { getFileTypeIcon } from '@/util/document';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import { formatStampAsHumanReadableDate } from '@/util/timeFunctions';
import { humanReadableDateFormatShort } from '@/variables/date-format';
import ImageViewModal from '@/components/Documents/ImageViewModal.vue';
import { slotEmpty } from '@/util/globals';
import VButton from '@/components/Inputs/VButton.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { safeHtmlStringify } from '@/util/safe-html-stringify';

type Props = {
  document: DocumentResource;
  folderId?: number | null;
  number?: number;
  canEdit?: boolean;
  withFolder?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  folderId: null,
  number: 0,
  canEdit: false,
  withFolder: true,
});

defineEmits<{
  (e: 'edit', document: DocumentResource): void;
  (e: 'removeDocumentFromFolder', document: DocumentResource): void;
  (e: 'addDocumentToFolder', document: DocumentResource): void;
}>();

const highlightSubstring = inject('highlightSubstring', (string) => string);
const { isSmallScreen } = useSmallScreen();

const newTitle = ref<string | null>(null);
const openEdit = ref(false);
const loading = ref(false);
const showDocumentModalOpen = ref(false);
const hover = ref(false);

const openNameModal = () => {
  newTitle.value = props.document.filename;
  openEdit.value = false;
  nextTick(() => {
    openEdit.value = true;
  });
};

const deleteDocument = async () => {};

const downloadDocument = async () => {
  if (loading.value) return;
  const splitName = props.document.filename.split('.');
  const fileName =
    splitName[splitName.length - 1] === props.document.extension
      ? props.document.filename
      : `${props.document.filename}.${props.document.extension}`;
  loading.value = true;
  await downloadFile(props.document.download_url, fileName);
  loading.value = false;
};

const openDocument = () => {
  if (props.document.mime_type.substring(0, 5) === 'image') {
    showDocumentModalOpen.value = false;
    nextTick(() => {
      showDocumentModalOpen.value = true;
    });
    return;
  }
  if (props.document.mime_type === 'application/pdf') {
    window.open(props.document.download_url);
    return;
  }
  downloadDocument();
};

const closeShowDocumentModal = () => {
  showDocumentModalOpen.value = false;
};

const currentIndentFile = (number: number) => {
  if (number === 0) {
    return 'text-indent: 0 px';
  }
  const num = Number(number * 30);
  return `text-indent: ${num}px`;
};

onBeforeUnmount(() => {
  closeShowDocumentModal();
});
</script>
<template>
  <VTableRow
    clickable
    class="group"
    @click="openDocument">
    <VTableCell
      colspan="2"
      main-cell
      class="whitespace-nowrap px-4">
      <div class="px-5 truncate">
        <i
          v-if="!hover || !canEdit"
          :class="loading ? ' fa-circle-o-notch fa-spin ' : getFileTypeIcon(document.extension)"
          class="fa-sm fa-fw fa mr-3 text-highlight"
          :style="'margin-left: ' + number * 22 + 'px;'" />
        <i
          v-if="hover && canEdit"
          class="fas fa-arrows-alt ml-5" />
        <span class="inline max-w-xs overflow-hidden whitespace-nowrap truncate"
          ><span
            v-html="
              safeHtmlStringify(
                highlightSubstring(document.filename.replace(new RegExp(`.${document.extension}$`), ''))
              )
            " /><span class="text-textColor-soft truncate"
            >{{ document.extension && '.'
            }}<span v-html="safeHtmlStringify(highlightSubstring(document.extension))" /></span
        ></span>
      </div>
    </VTableCell>
    <VTableCell
      v-if="!isSmallScreen"
      :title="document.created_at"
      colspan="2"
      class="overflow-hidden whitespace-nowrap text-right uppercase group-hover:text-textColor-soft group-hover:[&_span]:text-highlight">
      <div class="text-xs">
        Uploaded:
        <span>
          {{ formatStampAsHumanReadableDate(document.created_at, humanReadableDateFormatShort) }}
        </span>
        By:
        <span>
          {{ document && document.user && document.user.name ? document.user.name : 'Unknown' }}
        </span>
      </div>
    </VTableCell>
    <slot
      v-if="!slotEmpty($slots, 'buttons')"
      name="buttons"
      @click.stop />
    <VTableCell>
      <VButton
        v-if="canEdit"
        size="inTable"
        class="btn-xs"
        icon="fa-pencil"
        @click.stop="$emit('edit', document)" />
      <VButton
        v-else
        icon="fa-download"
        class="btn-xs"
        size="inTable"
        @click.stop="downloadDocument" />
    </VTableCell>
  </VTableRow>

  <ImageViewModal
    v-if="showDocumentModalOpen"
    :image="document"
    :can-edit="canEdit"
    @edit="[$emit('edit', document), (showDocumentModalOpen = false)]"
    @close="showDocumentModalOpen = false" />
</template>
