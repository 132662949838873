<script lang="ts" setup>
import { getRoomBooking, OtherUse, Restriction } from '@/services/api-room-booking';
import { RoomBookingResource } from '@/types/room-booking';
import { z } from 'zod';
import { nextTick, ref, watch } from 'vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VButton from '@/components/Inputs/VButton.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { getKey } from '@/util/globals';
import { formatStampAsDate, formatStampAsDateTime, timeStampsAreSame } from '@/util/timeFunctions';
import CheckBox from '@/components/Icons/CheckBox.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { useDebounceFn } from '@vueuse/core';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';

type Props = {
  initialBooking: RoomBookingResource | null;
  room: {
    id: number;
    name: string;
    title: string;
  };
  start: string;
  end: string;
  isRecurring?: boolean;
};

const props = withDefaults(defineProps<Props>(), { isRecurring: false, initialBooking: null });

const emit = defineEmits<{
  (event: 'closed'): void;
  (
    event: 'bookRoom',
    arg: {
      booking: {
        room_id: number;
        start: string;
        end: string;
        title: string | null;
        confirmed: boolean;
        is_global: boolean;
      };
      close: () => void;
    }
  ): void;
  (
    event: 'patchBooking',
    arg: {
      booking: {
        start: string;
        end: string;
        title: string | null;
        confirmed: boolean;
      };
      close: () => void;
    }
  ): void;
  (event: 'removeBooking', arg: { booking: RoomBookingResource; close: () => void }): void;
}>();

const roomBookingSchema = z.object({
  start: z.string(),
  end: z.string(),
  title: z.string().nullable(),
  confirmed: z.boolean(),
});

type NewRoomBookingResource = z.infer<typeof roomBookingSchema>;

const roomBookingForm = ref<NewRoomBookingResource>({
  start: getKey(props.initialBooking, 'start', props.start),
  end: getKey(props.initialBooking, 'end', props.end),
  title: getKey(props.initialBooking, 'title', ''),
  confirmed: getKey(props.initialBooking, 'confirmed', true),
  is_global: getKey(props.initialBooking, 'is_global', false),
});
const ignoreOtherUses = ref(false);
const ignoreRestrictions = ref(false);
const otherUses = ref<OtherUse[]>([]);
const restrictions = ref<Restriction[]>([]);
const loadingRestrictions = ref(false);
const checkAvailability = async () => {
  loadingRestrictions.value = true;
  const { data } = await getRoomBooking(props.room.id, roomBookingForm.value.start, roomBookingForm.value.end);
  otherUses.value = getKey(data, 'other_uses', []).filter((b) => {
    if (props.initialBooking) {
      return props.initialBooking.id !== b.id;
    }
    return true;
  });
  restrictions.value = getKey(data, 'restrictions', []);
  if (otherUses.value.length === 0 && otherUses.value.length === 0) {
    ignoreOtherUses.value = true;
    ignoreRestrictions.value = true;
    loadingRestrictions.value = false;
  } else {
    ignoreOtherUses.value = false;
    ignoreRestrictions.value = false;
    loadingRestrictions.value = false;
  }
};
const initialCheck = async () => {
  await checkAvailability();
  await nextTick();
  ignoreOtherUses.value = true;
  ignoreRestrictions.value = true;
};
initialCheck();
const debouncedFn = useDebounceFn(() => {
  checkAvailability();
}, 1000);

watch([() => roomBookingForm.value.start, () => roomBookingForm.value.end], () => {
  loadingRestrictions.value = true;
  debouncedFn();
});

const ignoreAll = () => {
  ignoreOtherUses.value = true;
  ignoreRestrictions.value = true;
};

const onCreated = (close: () => void) => {
  emit('bookRoom', {
    booking: {
      room_id: props.room.id,
      start: roomBookingForm.value.start,
      end: roomBookingForm.value.end,
      title: roomBookingForm.value.title,
      confirmed: roomBookingForm.value.confirmed,
      is_global: roomBookingForm.value.is_global,
      room_name: props.room?.name,
    },
    close: close,
  });
};

const onUpdate = (close: () => void) => {
  if (!props.initialBooking) return;

  emit('patchBooking', {
    booking: {
      start: roomBookingForm.value.start,
      end: roomBookingForm.value.end,
      title: roomBookingForm.value.title,
      confirmed: roomBookingForm.value.confirmed,
      is_global: roomBookingForm.value.is_global,
    },
    close: close,
  });
};

const onDelete = (close: () => void) => {
  if (!props.initialBooking) return;
  emit('removeBooking', { booking: props.initialBooking, close: close });
};
</script>

<template>
  <CrudModal
    :title="(initialBooking?.id ? 'Edit Booking of ' : 'Add') + room?.name"
    :title-highlight="room?.name"
    create-button-text="Book room"
    :update="initialBooking && initialBooking.id !== null"
    :disabled="
      ((!ignoreRestrictions || !ignoreOtherUses) && otherUses.length + restrictions.length > 0) || loadingRestrictions
    "
    large
    @create="onCreated"
    @update="onUpdate"
    @delete="onDelete"
    @closed="$emit('closed')">
    <div class="form-layout">
      <div class="col-span-2">
        <StartEndPicker
          v-model:start="roomBookingForm.start"
          v-model:end="roomBookingForm.end"
          required
          vertical
          with-duration
          with-time />
      </div>
      <div class="col-span-2 grid grid-cols-[auto_200px] gap-10">
        <TextInput
          v-model="roomBookingForm.title"
          label="Room title" />

        <div class="flex flex-col gap-5">
          <SettingCheck
            v-model="roomBookingForm.confirmed"
            label="Confirm booking" />

          <SettingCheck
            v-if="isRecurring && !initialBooking?.id"
            v-model="roomBookingForm.is_global"
            label="Recurring booking" />
        </div>
      </div>
      <div
        v-if="otherUses.length + restrictions.length > 0"
        class="col-start-2">
        <VButton
          v-if="!ignoreOtherUses || !ignoreRestrictions"
          class="float-right"
          size="small"
          @click="ignoreAll">
          Ignore All
        </VButton>
      </div>
    </div>

    <div class="transition-all">
      <div
        :class="loadingRestrictions || otherUses.length + restrictions.length > 0 ? 'h-[20vh]' : 'h-[60px]'"
        class="mt-5 overflow-auto transition-all">
        <div
          v-if="loadingRestrictions"
          class="mt-10 flex flex-col items-center gap-5">
          <span class="text-textColor-soft"> Checking for Restrictions or Other Uses </span>
          <IconWithLoading
            class="mx-auto text-3xl"
            loading />
        </div>
        <div v-else>
          <div
            v-if="otherUses.length + restrictions.length === 0"
            class="col-span-2">
            <InputLabel
              label="Notes"
              super-text
              class="!text-pending"></InputLabel>
            <span class="text-textColor-soft"> No overlapping bookings or restrictions in the same time period </span>
          </div>
          <div
            v-if="otherUses.length"
            class="col-span-2">
            <h3>
              Active conflicts (
              <span class="text-pending">
                {{ otherUses.length }}
              </span>
              )
            </h3>
            <VTable>
              <template #head>
                <VTableRow head>
                  <VTableCell> Confirmed</VTableCell>
                  <VTableCell> Event</VTableCell>
                  <VTableCell> Start</VTableCell>
                  <VTableCell> End</VTableCell>
                </VTableRow>
              </template>
              <VTableRow
                v-for="use in otherUses"
                :key="use.id">
                <VTableCell>
                  <CheckBox
                    :model-value="use.confirmed"
                    :can-edit="false" />
                </VTableCell>
                <VTableCell>
                  {{ use.event.name }}
                </VTableCell>
                <VTableCell :title="formatStampAsDateTime(use.start)">
                  {{
                    formatStampAsDate(
                      use.start,
                      timeStampsAreSame(use.start, use.end, 'day') ? 'HH:mm' : 'YYYY/MM/DD [at] HH:mm'
                    )
                  }}
                  <!--                  {{ use.start }}-->
                </VTableCell>
                <VTableCell>
                  {{
                    formatStampAsDate(
                      use.end,
                      timeStampsAreSame(use.start, use.end, 'day') ? 'HH:mm' : 'YYYY/MM/DD [at] HH:mm'
                    )
                  }}
                  <!--                  {{ use.end }}-->
                </VTableCell>
              </VTableRow>
            </VTable>
          </div>
          <div
            v-if="restrictions.length"
            class="col-span-2 mt-2">
            <h3>
              Active restrictions (
              <span class="text-pending">
                {{ restrictions.length }}
              </span>
              )
            </h3>
            <VTable>
              <template #head>
                <VTableRow head>
                  <VTableCell> Confirmed</VTableCell>
                  <VTableCell> Room</VTableCell>
                  <VTableCell> Message</VTableCell>
                  <VTableCell> Event</VTableCell>
                  <VTableCell> Duration</VTableCell>
                </VTableRow>
              </template>
              <VTableRow
                v-for="use in restrictions"
                :key="`${use.room_id}_${use.eventI}`">
                <VTableCell>
                  <CheckBox
                    :model-value="use.confirmed"
                    :can-edit="true" />
                </VTableCell>
                <VTableCell>
                  {{ use.room_name }}
                </VTableCell>
                <VTableCell>
                  {{ use.message }}
                </VTableCell>
                <VTableCell>
                  {{ use.eventName }}
                </VTableCell>
                <VTableCell
                  :title="
                    formatStampAsDate(use.start, 'YYYY/MM/DD [at] HH:mm') +
                    ' - ' +
                    formatStampAsDate(use.end, 'YYYY/MM/DD [at] HH:mm')
                  ">
                  {{
                    formatStampAsDate(
                      use.start,
                      timeStampsAreSame(use.start, use.end, 'day') ? 'HH:mm' : 'YYYY/MM/DD [at] HH:mm'
                    )
                  }}
                  -
                  {{
                    formatStampAsDate(
                      use.end,
                      timeStampsAreSame(use.start, use.end, 'day') ? 'HH:mm' : 'YYYY/MM/DD [at] HH:mm'
                    )
                  }}
                </VTableCell>
              </VTableRow>
            </VTable>
          </div>
        </div>
      </div>
    </div>
  </CrudModal>
</template>
