<script setup lang="ts">
import { VenueRoomResource } from '@/types/venue';
import { InviteResource } from '@/types/invite';
import { computed, nextTick, ref } from 'vue';
import {
  changeAndFormatStamp,
  formatStampAsDate,
  formatStampAsDateTime,
  formatStampAsTime,
  timeStampsAreSame,
} from '@/util/timeFunctions';
import { usePage } from '@inertiajs/vue3';
import { arrayToJoinString, getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import { concatName } from '@/services/api-partners';
import { useVenueRooms } from '@/composables/use-venue-rooms';
import { useToast } from 'vue-toastification';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VButton from '@/components/Inputs/VButton.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import SearchSelectFloatingWindow from '@/components/Inputs/Components/SearchSelectFloatingWindow.vue';
import PartnerCompanyCreateModal from '@/components/Partners/PartnerCompanyCreateModal.vue';
import PartnerContactCreateModal from '@/components/Partners/PartnerContactCreateModal.vue';
import { useEmitStore } from '@/store/EmitStore';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { groupRecurringDates } from '@/helpers/recurringFunctions';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import { getRoomBooking } from '@/services/api-room-booking';

type Props = {
  originalStart?: string | null;
  originalEnd?: string | null;
  originalRooms?: VenueRoomResource[];
  group: {
    id: number;
    has_recurring: boolean;
    has_partners: boolean;
  };
  venueId: number;
  invite: InviteResource | null;
};

const props = withDefaults(defineProps<Props>(), {
  originalStart: null,
  originalEnd: null,
  originalRooms: () => [],
});

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'created', ...args: []): void;
  (event: 'updated', ...args: []): void;
  (event: 'noLongerSimple', ...args: []): void;
}>();

const { venueRooms: rooms, fetch: fetchRooms } = useVenueRooms(props.venueId, props.group.id, false);
const toast = useToast();

const modalOpen = ref(false);
const event = ref({
  name: '',
  description: '',
  start: '',
  end: '',
});
const roomBookings = ref([]);
const partnerContacts = ref([]);
const partnerCompanies = ref([]);

const working = ref(false);
const firstLoadEditingBooking = ref(true);
const editMode = ref(false);

const isStillSimple = ref(true);
const duplicateDates = ref([]);

const canSave = computed(() => {
  if (!event.value) return false;
  const hasName = event.value.name.length;
  const hasBookings = roomBookings.value.length > 0;
  const hasAcceptedBookings = roomBookings.value.filter((b) => !b.isOk).length === 0;

  return [hasName, hasBookings, hasAcceptedBookings].every((v) => v);
});

const saveableEvent = computed(() => {
  return {
    name: event.value.name,
    is_simple: true,
    start_date: formatStampAsDate(event.value.start),
    end_date: formatStampAsDate(event.value.end),
    start: formatStampAsDateTime(event.value.start),
    end: formatStampAsDateTime(event.value.end),
    description: event.value.description,
    project_leader_id: usePage().props.auth.user.id || null,
    partnerCompanies: partnerCompanies.value.map((partner) => {
      return {
        id: partner.id,
      };
    }),
    partnerContacts: partnerContacts.value.map((partner) => {
      return {
        id: partner.id,
      };
    }),
    requested_venue_id: props.venueId,
    roomBookings: savableRoomBookings.value,
    duplicate_dates: duplicateDates.value,
  };
});

const savableRoomBookings = computed(() => {
  return roomBookings.value
    .filter((b) => b.isOk)
    .map((b) => ({
      title: b.title,
      start: formatStampAsDateTime(event.value.start),
      end: formatStampAsDateTime(event.value.end),
      room_id: b.room_id,
      confirmed: true,
    }));
});

const createSimpleEvent = async (close: () => void) => {
  if (!canSave.value) return;
  if (roomBookings.value.length === 0) {
    toast.warning('Must add at least one room');
    return;
  }
  if (working.value) return;
  working.value = true;
  await axios.post(`/api/groups/${props.group.id}/events`, saveableEvent.value).catch((error) => {
    toast.warning('Could not create assignment');
    console.error(error.response.data);
  });

  toast.success('Created');
  emit('created');
  close();
};

const updateSimpleEvent = async (close: () => void) => {
  if (!canSave.value) return;
  if (!props.invite) return;
  if (!props.invite.event) return;
  if (!props.invite.event.id) return;
  if (roomBookings.value.length === 0) {
    toast.warning('Must add at least one room');
    return;
  }
  if (working.value) return;
  working.value = true;
  await axios
    .post(`/api/events/ ${props.invite.event.id}`, {
      name: event.value.name,
      description: event.value.description,
      start_date: formatStampAsDate(event.value.start),
      end_date: formatStampAsDate(event.value.end),
      requested_venue_id: props.venueId,
      is_simple: isStillSimple.value,
    })
    .catch((error) => {
      toast.warning('Could not Update Booking');
      console.error(error);
    });
  await axios
    .patch(`/api/invites/${props.invite.id}/dates`, {
      start: formatStampAsDateTime(event.value.start),
      end: formatStampAsDateTime(event.value.end),
    })
    .catch((error) => {
      toast.warning('Could not update');
      console.error(error.response.data);
    });

  for (let i = 0; i < roomBookings.value.length; i++) {
    await patchOrCreateBooking(roomBookings.value[i]);
  }
  toast.success('Updated');
  if (isStillSimple.value) {
    emit('updated');
  } else {
    emit('noLongerSimple');
  }
  close();
};

const patchOrCreateBooking = async (booking) => {
  if (booking.hasOwnProperty('persisted') && booking.persisted) {
    await axios
      .patch(`/api/room-booking/${booking.id}`, {
        start: formatStampAsDateTime(event.value.start),
        end: formatStampAsDateTime(event.value.end),
        title: booking.title,
        confirmed: true,
      })
      .catch((error) => {
        console.error(error);
        toast.warning('Something went wrong - please try again later.');
      });
  } else {
    await axios
      .post('/api/room-booking', {
        invite_id: props.invite.id,
        room_id: booking.room_id,
        start: formatStampAsDateTime(event.value.start),
        end: formatStampAsDateTime(event.value.end),
        title: booking.title,
        confirmed: true,
      })
      .catch((error) => {
        console.error(error);
        toast.warning('Something went wrong - please try again later.');
      });
  }
};

const checkRoomAvailability = async (roomId: number) => {
  let index = getIndexFromArrayBasedOnId(roomId, roomBookings.value, 'room_id');
  if (index === -1) {
    roomBookings.value.push({
      room_id: roomId,
      id: null,
      restrictions: [],
      otherUse: [],
      isOk: true,
      title: null,
      loading: false,
    });
    index = getIndexFromArrayBasedOnId(roomId, roomBookings.value, 'room_id');
  }
  if (event.value.start) {
    roomBookings.value[index].loading = true;
    const { data } = await getRoomBooking(roomId, event.value.start, event.value.end);

    if (getKey(data, 'status') === 'Available') {
      roomBookings.value[index].isOk = true;
    } else {
      getKey(data, 'other_uses', [])
        .filter((o) => o.id !== roomBookings.value[index].id)
        .forEach((data) => {
          if (data.id === roomBookings.value[index].id) {
            return;
          }
          roomBookings.value[index].otherUse.push({
            event: data.event,
            accepted: firstLoadEditingBooking.value,
            start: data.start,
            end: data.end,
            confirmed: data.confirmed,
          });
        });
      getKey(data, 'restrictions', []).forEach((data) => {
        roomBookings.value[index].restrictions.push({
          accepted: firstLoadEditingBooking.value,
          other_room_id: data.room_id,
          other_room_name: data.room_name,
          message: data.message,
          start: data.start,
          end: data.end,
          eventName: data.eventName,
          confirmed: data.confirmed,
        });
      });
      roomBookings.value[index].isOk =
        roomBookings.value[index].restrictions.length + roomBookings.value[index].otherUse.length === 0;
    }
    roomBookings.value[index].loading = false;
  }
};

const deleteEvent = async (close: () => void) => {
  if (!props.invite) return;
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Delete Booking',
    'Are you sure you want to remove this booking? This is permanent.'
  );
  if (!deleteIt) return;
  await axios.delete(`/api/events/${props.invite.event.id}`).catch(() => {
    toast.warning('Could not delete booking');
  });
  toast.success('Deleted');
  emit('updated');
  close();
};

const convertToEvent = async (close: () => void) => {
  const { rootEmit } = useEmitStore();
  rootEmit('close-all-drop-downs');
  if (!props.invite) return;
  const certain = await useCertaintyModal().assertCertain(
    'Convert into Event',
    `Are you sure you want to convert ${event.value.name} from a room booking into an event? This is permanent.`
  );
  if (!certain) return;
  isStillSimple.value = false;
  await updateSimpleEvent(close);
};

const checkIfAllBookingsInsideScopeOfRoom = async () => {
  if (working.value) return;
  working.value = true;
  await nextTick();
  for (let i = 0; i < roomBookings.value.length; i++) {
    await checkRoomAvailability(Number(roomBookings.value[i].room_id));
  }
  working.value = false;
};

const removeBooking = async (booking) => {
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Room from Booking',
    'Are you sure you want to remove ' +
      getItemFromArrayBasedOnId(booking.room_id, rooms.value, { name: 'this room' }).name +
      '? This is permanent.'
  );
  if (!deleteIt) return;
  if (booking.persisted) {
    await axios.delete(`/api/room-booking/${booking.id}`).catch(() => {
      toast.warning('Could not delete booking');
    });
  }
  const index = roomBookings.value.indexOf(booking);
  if (index > -1) {
    roomBookings.value.splice(index, 1);
  }
  toast.success('Room has been removed');
};

const getRestrictionText = (booking) => {
  if (booking.restrictions.length) {
    const reasons = [];
    booking.restrictions.forEach((restriction) => {
      const roomName = getKey(restriction, 'other_room_name', getKey(restriction, 'room_name', 'A Room'));
      reasons.push({
        reason: `${restriction.eventName} in ${roomName}`,
        message: restriction.message,
        title: `${roomName} is booked for ${restriction.eventName} from ${formattedRestrictionDate(restriction.start)} to ${formattedRestrictionDate(restriction.end)}`,
      });
    });
    return reasons;
  }
  return {};
};
const getOtherUseText = (booking) => {
  if (booking.otherUse.length) {
    let reasons = [];
    booking.otherUse.forEach((otherUse) => {
      reasons.push({
        eventName: otherUse.event.name,
        title: `${otherUse.event.name} has booked ${
          getItemFromArrayBasedOnId(booking.room_id, rooms.value, { name: '' }).name
        }
            from ${formattedRestrictionDate(otherUse.start)} to ${formattedRestrictionDate(otherUse.end)}`,
      });
    });
    return reasons;
  }
  return [];
};
const formattedRestrictionDate = (time) => {
  if (timeStampsAreSame(time, event.value.start, 'day')) {
    return formatStampAsTime(time);
  }
  return formatStampAsDateTime(time, 'MM-DD HH:mm');
};

const attachCompany = async (company) => {
  if (props.invite) {
    await axios.post(`/api/partners/companies/${company.id}/invites/${props.invite.id}/attach`).then(() => {});
  }
  partnerCompanies.value.push({
    name: company.name,
    id: company.id,
    partner_type_id: company.partner_type_id,
  });
  toast.success(`${company.name} added`);
};

const detachCompany = async (company) => {
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Company',
    `Are you sure you want to remove ${company.name} from ${event.value.name} ?`
  );
  if (!deleteIt) return;
  if (props.invite) {
    await axios.post(`/api/partners/companies/${company.id}/invites/${props.invite.id}/detach`);
  }
  const index = getIndexFromArrayBasedOnId(company.id, partnerCompanies.value);
  if (index > -1) {
    partnerCompanies.value.splice(index, 1);
  }
  toast.success(`${company.name} removed`);
};

const attachContact = async (contact) => {
  if (props.invite) {
    await axios.post(`/api/partners/contacts/${contact.id}/invites/${props.invite.id}/attach`);
  }
  partnerContacts.value.push({
    first_name: contact.first_name,
    last_name: contact.last_name,
    phone: contact.phone,
    email: contact.email,
    id: contact.id,
  });
  toast.success(`${concatName(contact)} added`);
};

const detachContact = async (contact) => {
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Contact',
    `Are you sure you want to remove ${concatName(contact)} from ${event.value.name} ?`
  );
  if (!deleteIt) return;
  if (props.invite) {
    await axios.post(`/api/partners/contacts/${contact.id}/invites/${props.invite.id}/detach`);
  }
  const index = getIndexFromArrayBasedOnId(contact.id, partnerContacts.value);
  if (index > -1) {
    partnerContacts.value.splice(index, 1);
  }
  toast.success(`${concatName(contact)} removed.`);
};

const fetchRoomBookings = async () => {
  if (!props.invite) return;
  working.value = true;
  const { data } = await axios.get(`/api/invites/${props.invite.id}/room-booking`);
  roomBookings.value = [];
  data.forEach((booking) => {
    roomBookings.value.push({
      persisted: true,
      room_id: booking.room_id,
      id: booking.id,
      restrictions: [],
      otherUse: [],
      isOk: true,
      title: booking.room_title ? booking.room_title : '',
    });
  });
  await fetchPartners();
  working.value = false;
};
const fetchPartners = async () => {
  if (!props.invite) return;
  const { data } = await axios.get(`/api/invites/${props.invite.id}/partners`);
  partnerContacts.value = data.contacts;
  partnerCompanies.value = data.companies;
};

const setup = async () => {
  modalOpen.value = true;
  if (props.invite) {
    editMode.value = false;
    event.value = {
      name: props.invite.event.name,
      description: props.invite.event.description,
      start: formatStampAsDateTime(props.invite.start),
      end: formatStampAsDateTime(props.invite.end),
    };
    nextTick(() => {
      fetchRoomBookings();
    });
  } else {
    if (!props.originalEnd || !props.originalStart) {
      modalOpen.value = false;
      toast.warning('Start And End Missing');
      return;
    }
    editMode.value = true;
    event.value = {
      name: '',
      description: '',
      start: props.originalStart,
      end: props.originalEnd ? props.originalEnd : changeAndFormatStamp({ stamp: props.originalStart, addMinutes: 60 }),
    };

    for (let i = 0; i < props.originalRooms.length; i++) {
      await checkRoomAvailability(Number(props.originalRooms[i]));
    }
    firstLoadEditingBooking.value = false;
  }
  await fetchRooms(false);
};
setup();

const searchForCompany = ref(false);
const searchForContact = ref(false);
const dateSelectorOpen = ref(false);
const pageY = ref(null);
const pageX = ref(null);
const openCompanySearch = (event, newSearchForCompany = true) => {
  searchForCompany.value = false;
  searchForContact.value = false;
  dateSelectorOpen.value = false;
  pageY.value = null;
  pageX.value = null;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - (newSearchForCompany ? 200 : 300);
    pageY.value = event.target.getBoundingClientRect().y + 38;
    searchForCompany.value = newSearchForCompany;
    searchForContact.value = !newSearchForCompany;
  });
};
const openDateSelector = (event) => {
  searchForCompany.value = false;
  searchForContact.value = false;
  dateSelectorOpen.value = false;
  pageY.value = null;
  pageX.value = null;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - 200;
    pageY.value = event.target.getBoundingClientRect().y + 38;
    dateSelectorOpen.value = true;
  });
};

const createCompanyName = ref<string | null>(null);
const showCreatePartnerSlideOut = ref(false);

const createCompany = (companyName: string) => {
  createCompanyName.value = companyName;
  showCreatePartnerSlideOut.value = false;
  nextTick(() => {
    showCreatePartnerSlideOut.value = true;
  });
};
const newPartnerContactName = ref('');
const showPartnerContactModal = ref(false);
const createPartnerContact = (name: string) => {
  newPartnerContactName.value = name;
  showPartnerContactModal.value = false;
  nextTick(() => {
    showPartnerContactModal.value = true;
  });
};

const sortedDuplicateDates = computed(() => {
  return _.orderBy(groupRecurringDates(_.orderBy(duplicateDates.value)));
});
</script>
<template>
  <CrudSlideout
    v-if="modalOpen"
    :update="invite !== null"
    :title="invite === null ? 'Create Drop-In Booking' : 'Update Drop-In Booking'"
    title-highlight="Drop-In Booking"
    small
    :disabled="!canSave"
    :header-actions="[
      !editMode
        ? {
            title: 'Edit',
            icon: 'fa-pencil',
            action: () => {
              editMode = true;
            },
          }
        : {
            title: 'Cancel Edit',
            icon: 'fa-times',
            action: () => {
              editMode = false;
            },
          },
    ]"
    main-content-classes="[&_.h-navbar]:h-10"
    :loading="working"
    create-button-text="Create"
    @delete="deleteEvent"
    @update="updateSimpleEvent"
    @create="createSimpleEvent"
    @closed="$emit('closed')">
    <div class="flex flex-col [&>div:last-child]:border-none [&>div]:border-b [&>div]:pb-7">
      <div class="form-layout p-edge">
        <TextInput
          v-model="event.name"
          :can-edit="editMode"
          :set-focus="true"
          :is-hidden="!editMode"
          label="Name" />

        <TextareaInput
          v-model="event.description"
          :can-edit="editMode"
          :is-hidden="!editMode"
          :min-rows="1"
          label="Description" />

        <StartEndPicker
          v-model:start="event.start"
          v-model:end="event.end"
          :can-edit="editMode"
          :is-hidden="!editMode"
          required
          vertical
          :allow-no-duration="false"
          with-duration
          with-time
          @update:start="checkIfAllBookingsInsideScopeOfRoom"
          @update:end="checkIfAllBookingsInsideScopeOfRoom" />
      </div>

      <div>
        <div class="flex justify-between p-edge">
          <h3 class="">Rooms ({{ roomBookings.length }})</h3>
          <ActionButtonGroup
            v-if="editMode"
            :actions="[
              {
                title: 'Add Room',
                icon: 'fa-plus',
                maxHeightDropdown: '50vh',
                dropdown: [
                  {
                    title: 'Add Room',
                    type: 'header',
                  },
                ].concat(
                  rooms.map((r) => {
                    return {
                      title: r.name,
                      selected: roomBookings.map((b) => b.room_id).includes(r.id),
                      action: (close: () => void) => {
                        if (roomBookings.map((b) => b.room_id).includes(r.id)) {
                          removeBooking(r);
                        } else {
                          checkRoomAvailability(r.id);
                        }
                        close();
                      },
                    };
                  })
                ),
              },
            ]" />
        </div>

        <EmptyStateFullPage
          v-if="roomBookings.length === 0"
          icon="fa-cube fa-sm"
          size="tiny"
          :button-function="null"
          description="No Rooms Added" />
        <VTable
          v-if="roomBookings.length > 0"
          row-size="small"
          un-striped
          edge-to-edge>
          <template #head>
            <VTableRow head>
              <VTableCell
                v-if="editMode"
                style="width: 50px"></VTableCell>
              <VTableCell>Room</VTableCell>
              <VTableCell style="width: 200px">Title</VTableCell>
              <VTableCell
                v-if="editMode"
                style="width: 70px"></VTableCell>
            </VTableRow>
          </template>
          <template v-for="booking in roomBookings">
            <VTableRow
              :class="[
                booking.isOk
                  ? ''
                  : ' !bg-pending hover:!bg-pending/90  [&:hover_td]:!bg-pending/90 [&_*]:!text-black [&_td]:!bg-pending',
              ]">
              <VTableCell
                v-if="editMode"
                :main-cell="!booking.isOk">
                <CheckBox
                  :model-value="booking.isOk"
                  :loading="booking.loading"></CheckBox>
              </VTableCell>
              <VTableCell main-cell>
                {{ getItemFromArrayBasedOnId(booking.room_id, rooms, { name: '' }).name }}
              </VTableCell>
              <VTableCell class="!p-0">
                <TextInput
                  v-model="booking.title"
                  placeholder="Room Title"
                  :can-edit="editMode"
                  is-hidden></TextInput>
              </VTableCell>
              <VTableCell>
                <VButton
                  v-if="editMode"
                  size="inTable"
                  class="float-right"
                  icon="fa-trash"
                  @click.stop="removeBooking(booking)"></VButton>
              </VTableCell>
            </VTableRow>
            <VTableRow
              v-if="booking.otherUse.length && !booking.isOk"
              :key="'otherUse_' + booking.id"
              no-background>
              <VTableCell
                :colspan="3"
                main-cell>
                <div class="grid grid-cols-[30px_auto] items-center">
                  <div>
                    <i class="fa fa-fw fa-info text-2xl text-textColor" />
                  </div>
                  <div class="flex flex-col">
                    <div class="sub-title text-highlight">
                      Other uses of
                      {{ getItemFromArrayBasedOnId(booking.room_id, rooms, { name: '' }).name }}
                    </div>
                    <div
                      v-for="reason in getOtherUseText(booking)"
                      :key="reason.id"
                      class="ml-4 p-2"
                      :title="reason.title">
                      <strong>Event: </strong>{{ reason.eventName }}
                    </div>
                  </div>
                </div>
              </VTableCell>
              <VTableCell main-cell>
                <VButton
                  size="inTable"
                  title="Ignore"
                  class="float-right"
                  @click="[(booking.otherUse = []), (booking.isOk = booking.restrictions.length === 0)]" />
              </VTableCell>
            </VTableRow>
            <VTableRow
              v-if="booking.restrictions.length && !booking.isOk"
              :key="'restricitons_' + booking.id"
              no-background>
              <VTableCell
                :colspan="3"
                main-cell>
                <div class="grid grid-cols-[30px_auto] items-center">
                  <div>
                    <i class="fa fa-fw fa-info text-2xl text-textColor" />
                  </div>
                  <div class="striped-children flex flex-col">
                    <div class="sub-title text-highlight">
                      Restrictions on usage of
                      {{ getItemFromArrayBasedOnId(booking.room_id, rooms, { name: '' }).name }}
                    </div>
                    <div
                      v-for="text in getRestrictionText(booking)"
                      :key="text.id"
                      class="ml-4 p-2"
                      :title="text.title">
                      <strong>Reason: </strong><small>{{ text.reason }}</small>
                      <br />
                      <strong>Issue: </strong><small>{{ text.message }}</small>
                    </div>
                  </div>
                </div>
              </VTableCell>
              <VTableCell main-cell>
                <VButton
                  size="inTable"
                  title="Ignore"
                  class="float-right"
                  @click="[(booking.restrictions = []), (booking.isOk = booking.otherUse.length === 0)]" />
              </VTableCell>
            </VTableRow>
          </template>
        </VTable>
      </div>
      <div>
        <div class="flex justify-between p-edge">
          <h3 class="">Partners</h3>
          <ActionButtonGroup
            v-if="editMode"
            :actions="[
              {
                title: 'Add Company',
                icon: 'fa-plus',
                action: ($event) => {
                  openCompanySearch($event, true);
                },
              },
              {
                title: 'Add Contact',
                icon: 'fa-plus',
                action: ($event) => {
                  openCompanySearch($event, false);
                },
              },
            ]" />
        </div>

        <SearchSelectFloatingWindow
          v-if="pageX && pageY && searchForCompany"
          url="/api/partners/companies"
          placeholder="Search For Company"
          :debounce-time="500"
          can-create
          :params="{
            model_type: 'App\\Group',
            model_id: group.id,
          }"
          :page-y="pageY"
          :page-x="pageX"
          :already-selected-ids="partnerCompanies.map((item) => item.id)"
          @create="createCompany"
          @selected="attachCompany" />

        <PartnerCompanyCreateModal
          v-if="showCreatePartnerSlideOut"
          :init-company="{ name: createCompanyName }"
          :model-id="group.id"
          model="Group"
          :can-add-contacts="false"
          @closed="showCreatePartnerSlideOut = false"
          @saved="attachCompany($event)" />

        <SearchSelectFloatingWindow
          v-if="pageX && pageY && searchForContact"
          url="/api/partners/contacts"
          placeholder="Search For Contact"
          :debounce-time="500"
          can-create
          :params="{
            model_type: 'App\\Group',
            model_id: group.id,
          }"
          :page-y="pageY"
          :page-x="pageX"
          :already-selected-ids="partnerContacts.map((item) => item.id)"
          @create="createPartnerContact"
          @selected="attachContact" />

        <PartnerContactCreateModal
          v-if="showPartnerContactModal"
          :model-value="{ name: newPartnerContactName }"
          :model-id="group.id"
          model="Group"
          :can-add-company="false"
          @closed="showPartnerContactModal = false"
          @created="attachContact($event)" />

        <div class="form-layout grid-cols-[1fr_1fr] gap-x-4 gap-y-6">
          <div class="">
            <div
              v-if="partnerCompanies.length > 0"
              class="pl-edge">
              <h3>Companies</h3>
            </div>
            <VTable edge-to-edge>
              <VTableRow v-for="company in partnerCompanies">
                <VTableCell main-cell>
                  {{ company.name }}
                </VTableCell>
                <VTableCell style="width: 60px">
                  <VButton
                    v-if="editMode"
                    size="inTable"
                    icon="fa-trash"
                    @click="detachCompany(company)">
                  </VButton>
                </VTableCell>
              </VTableRow>
            </VTable>

            <EmptyStateFullPage
              v-if="partnerCompanies.length === 0"
              icon="fa-group"
              size="tiny"
              :button-function="null"
              description="No Companies Added" />
          </div>
          <div class="">
            <div
              v-if="partnerContacts.length > 0"
              class="pl-edge">
              <h3>Contacts</h3>
            </div>
            <VTable edge-to-edge>
              <VTableRow v-for="contact in partnerContacts">
                <VTableCell main-cell>
                  {{ concatName(contact) }}
                </VTableCell>
                <VTableCell style="width: 60px">
                  <VButton
                    v-if="editMode"
                    size="inTable"
                    icon="fa-trash"
                    @click="detachContact(contact)">
                  </VButton>
                </VTableCell>
              </VTableRow>
            </VTable>
            <EmptyStateFullPage
              v-if="partnerContacts.length === 0"
              icon="fa-user"
              size="tiny"
              :button-function="null"
              description="No Contacts Added" />
          </div>
        </div>
      </div>

      <div
        v-if="!invite"
        class="p-edge">
        <div class="flex justify-between">
          <h3 class="">Dates ({{ duplicateDates.length }})</h3>
          <ActionButtonGroup
            v-if="editMode"
            :actions="[
              {
                title: 'Add Date(s)',
                icon: 'fa-plus',
                primary: false,
                action: ($event) => {
                  openDateSelector($event);
                },
              },
            ]" />

          <FloatingWindowContainer
            v-if="pageX && pageY && dateSelectorOpen"
            :page-x="pageX"
            :page-y="pageY">
            <VDatepicker
              v-model="duplicateDates"
              multi-dates
              inline
              :disabled-dates="[event.start]"
              :start-date="event.start" />
          </FloatingWindowContainer>
        </div>
        <div class="flex flex-col gap-5">
          <div v-for="month in sortedDuplicateDates">
            <h4>
              {{ formatStampAsDate(month[0], 'MMMM YYYY') }}
            </h4>
            {{ arrayToJoinString(month.map((date) => formatStampAsDate(date, 'dddd Do'))) }}
          </div>
        </div>
        <EmptyStateFullPage
          v-if="duplicateDates.length === 0"
          icon="fa-calendar fa-sm"
          size="tiny"
          :button-function="null"
          description="No Additional Dates Added" />
      </div>
    </div>

    <template #footer="{ close }">
      <div v-if="!editMode"></div>
      <div
        v-if="invite && editMode"
        class="flex h-full w-full items-center justify-between px-edge">
        <VButton
          size="small"
          tool-tip-text="Convert drop-in booking to an event"
          title="Convert"
          :loading="working"
          @click="convertToEvent(close)" />
        <div>
          <VButton
            class="btn-warning btn-outline mr-5"
            title="Delete"
            :loading="working"
            @click="deleteEvent(close)" />
          <VButton
            type="success"
            title="Update"
            :disabled="working"
            :loading="working"
            @click="updateSimpleEvent(close)" />
        </div>
      </div>
    </template>
  </CrudSlideout>
</template>
