import { useModal } from 'vue-final-modal';
import VTextBoxDialog from '@/components/Modals/VTextBoxDialog.vue';

type Resolve = 'cancel' | 'confirm';

export const useTextBoxModal = (title: string, text: string, label: string, buttonText: string) => {
  const showTextBox = async () => {
    return new Promise<Resolve>((resolve) => {
      let resolveResponse: Resolve = 'cancel';
      let resolveText: string | null = null;
      const modelInstance = useModal({
        defaultModelValue: true,
        component: VTextBoxDialog,
        attrs: {
          title: title,
          fieldLabel: label,
          text: text,
          buttonText: buttonText,
          async onCancel() {
            resolveResponse = 'cancel';
            modelInstance.close();
          },
          async onConfirm(text: string | null) {
            resolveResponse = 'confirm';
            resolveText = text;
            modelInstance.close();
          },
          onClosed() {
            resolve({
              response: resolveResponse,
              text: resolveText,
            });
            modelInstance.destroy();
          },
        },
      });
    });
  };

  return { showTextBox };
};
