<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal';
import { ZIndexPopUpModal } from '@/variables/z-indexes';
import VButton from '@/components/Inputs/VButton.vue';
import { ref } from 'vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';

type Props = {
  title: string;
  fieldLabel: string;
  buttonText: string;
  text: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'cancel': [];
  'confirm': string | null;
  'closed': [];
}>();

const setIndex = ({ index }: { index: number }) => {
  if (index === 0) return ZIndexPopUpModal;
  return ZIndexPopUpModal + 2 * index;
};

const string = ref('');
const onClick = () => {
  emit('confirm', string.value);
};
</script>

<template>
  <VueFinalModal
    :content-transition="{
      'enter-active-class': 'transition duration-300 ease-in-out transform',
      'enter-from-class': 'scale-0 opacity-0',
      'enter-to-class': '',
      'leave-active-class': 'transition duration-200 ease-in-out transform',
      'leave-to-class': 'opacity-0',
      'leave-from-class': '',
    }"
    overlay-class="bg-[--color-backdrop-modal]"
    class="flex items-center justify-center"
    :z-index-fn="setIndex"
    :click-to-close="true"
    :content-class="`bg-backgroundColor rounded border w-[400px] text-textColor max-h-full `"
    @closed="$emit('closed')">
    <div class="relative m-2 p-5">
      <div class="grid grid-cols-[auto_30px] items-center">
        <h2 class="flex-1 font-headers">{{ title }}</h2>

        <button
          class="float-right h-8 w-8 rounded-full hover:bg-backgroundColor-content"
          style="margin-right: -23px; margin-top: -10px"
          @click.stop="$emit('cancel')">
          <i class="fa fa-fw fa-times" />
        </button>
      </div>
      <div class="py-4 text-base">
        {{ text }}
      </div>
      <div class="py-4 text-base">
        <TextareaInput
          v-model="string"
          :label="fieldLabel"
          :placeholder="fieldLabel"
          set-focus />
      </div>
      <div class="flex justify-end gap-2">
        <VButton
          :title="buttonText"
          type="success"
          @click="onClick" />
      </div>
    </div>
  </VueFinalModal>
</template>
